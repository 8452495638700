import { render, staticRenderFns } from "./HistoryList.vue?vue&type=template&id=6ec6ee6f&scoped=true&"
import script from "./HistoryList.vue?vue&type=script&lang=js&"
export * from "./HistoryList.vue?vue&type=script&lang=js&"
import style0 from "./HistoryList.vue?vue&type=style&index=0&id=6ec6ee6f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec6ee6f",
  null
  
)

export default component.exports